import { loginRequest } from "./authConfig.js";
import { useMsal } from "@azure/msal-react";
import { msalConfig} from "./authConfig.js";
import { PublicClientApplication } from "@azure/msal-browser";
export * from "@azure/msal-react";
export * from "./authConfig.js";

export const msalInstance = new PublicClientApplication(msalConfig);


export const handleLogin = async (email, loading, instance) => {
  try {
    // loading;
    localStorage.setItem("loading", "true");
    await instance.loginRedirect(loginRequest(email));
  } catch (error) {
    console.error(error);
  }
};

export const handleLogout = async (account, loading, instance) => {
  const logoutRequest = {
    account: instance.getAccountByHomeId(account),
  }
  
  try {
    // loading;
    localStorage.clear();
    await instance.logoutRedirect(logoutRequest);
    sessionStorage.clear();
  } catch (error) {
    console.error(error);
  }
}

