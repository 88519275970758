import { CLIENT_ID, REDIRECT_URL, TENANT_ID } from "../environments/config";



export const msalConfig = {
  //NTTDATA
  /*  auth: {
     clientId: "3cd0261f-11ed-49c0-aa71-4bdad29129ec",
     tenant:
     "https://login.microsoftonline.com/9dbc76ea-fb25-4b07-8f07-5dc315999b76",
     postLogoutRedirectUri: Config.PUBLIC_ASULADO_REDIRECT_URL,
   }, */
  //ASULADO
  auth: {
    clientId: CLIENT_ID,
    tenant: TENANT_ID,
    redirectUri: REDIRECT_URL,
    postLogoutRedirectUri: REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};


export const loginRequest = (email) => {
  return {
    scopes: ["User.Read"],
    loginHint: email,
  };
};


